import { FC, Fragment, useState } from 'react';
import { Input } from 'src/components/ui/Input';
import { getLandPlotValue, getPolygonFormSelectItemNew } from 'src/utils/helpers';
import { PolygonType, SelectItemType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';
import { landPlotsFields } from 'src/config/data';
import { Control, UseFormRegister } from 'react-hook-form';
import { SelectNew } from 'src/components/ui/SelectNew';

interface DashboardStatEditProps {
  resetField: (arg: string) => void;
  control: Control<PolygonType>;
  register: UseFormRegister<PolygonType>;
}

export const DashboardStatEdit: FC<DashboardStatEditProps> = ({
  resetField,
  register,
  control,
}) => {
  const { selectedPolygon } = useAppSelector(state => state.polygons);
  const { blockStatuses, owners, typeOwners, statuses, categories } = useAppSelector(
    state => state.categories,
  );

  const [curStatus, setCurStatus] = useState(
    statuses?.find(({ id }) => id === selectedPolygon?.status),
  );

  const handleChangeStatus = (status: SelectItemType) => {
    const selectedStatus = statuses.find(el => (el?.id || 0) === +status.value);

    if (!selectedStatus?.id) return;

    setCurStatus(selectedStatus);

    if (!selectedStatus?.substatuses?.length) {
      resetField('substatus');
    }
  };

  const textClassName =
    'text-grey-500 dark:text-grey-700 font-gothampro-400 text-xs t-ls:text-sm w-full t-sm:min-w-[10rem]';
  const lineClassName = 'w-full flex t-sm:flex-row flex-col items-center gap-1 t-sm:gap-2';

  return (
    <div className="flex flex-col gap-2 t-lg:gap-3.5">
      {landPlotsFields.map((field, i) => {
        const value = getLandPlotValue(
          field,
          selectedPolygon?.reestrInfo?.features?.[0].properties.options,
        );

        if (!value.length || value === ' ' || value === '  ()') {
          return <Fragment key={`object-info-line-edit-${i}`} />;
        }

        return (
          <div className={lineClassName} key={`object-info-line-edit-${i}`}>
            <div className={textClassName}>{field.name}</div>
            <Input defaultValue={value.includes('²') ? value.replace('²', '2') : value} disabled />
          </div>
        );
      })}
      <div className={lineClassName}>
        <div className={textClassName}>Направление:</div>
        <SelectNew
          placeholder="Направление"
          options={blockStatuses.map(el => getPolygonFormSelectItemNew(el))}
          name="block_status"
          control={control}
        />
      </div>
      <div className={lineClassName}>
        <div className={textClassName}>Группа объекта:</div>
        <SelectNew
          placeholder="Группа объекта"
          options={categories.map(el => getPolygonFormSelectItemNew(el))}
          name="category"
          control={control}
        />
      </div>
      <div className={lineClassName}>
        <div className={textClassName}>Правообладатель:</div>
        <SelectNew
          placeholder="Правообладатель"
          options={owners.map(el => getPolygonFormSelectItemNew(el))}
          name="owner"
          control={control}
        />
      </div>
      <div className={lineClassName}>
        <div className={textClassName}>Вид права:</div>
        <SelectNew
          placeholder="Вид права"
          options={typeOwners.map(el => getPolygonFormSelectItemNew(el))}
          name="type_owner"
          control={control}
        />
      </div>
      <div className={lineClassName}>
        <div className={textClassName}>Статус:</div>
        <SelectNew
          required
          placeholder="Статус"
          options={statuses.map(el => getPolygonFormSelectItemNew(el))}
          handleChange={handleChangeStatus}
          name="status"
          control={control}
        />
      </div>
      {curStatus?.substatuses?.length ? (
        <div className={lineClassName}>
          <div className={textClassName}>Подстатус:</div>
          <SelectNew
            placeholder="Подстатус"
            options={curStatus?.substatuses.map(el => getPolygonFormSelectItemNew(el))}
            name="substatus"
            control={control}
          />
        </div>
      ) : null}
      <div className={lineClassName}>
        <div className={textClassName}>Генеральный план</div>
        <Input
          data={{ name: 'master_plan', placeholder: 'Генеральный план' }}
          register={register}
        />
      </div>

      <div className={lineClassName}>
        <div className={textClassName}>ПЗЗ</div>
        <Input data={{ name: 'pzz', placeholder: 'ПЗЗ' }} register={register} />
      </div>
    </div>
  );
};
